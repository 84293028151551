import React from 'react'
import Layout from 'components/layout/layout'
import IndividualGrant from 'components/forms/individualGrant'

export default () => {
  return (
    <Layout>
      <IndividualGrant />
    </Layout>
  )
}
